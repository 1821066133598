import { Link } from 'gatsby';
import React from 'react';
import { container, tags } from './logo.module.css';

export const Logo: React.FC<{ tag: string | undefined }> = ({
  tag,
}) => {
  if (!tag) {
    return (
      <Link className={container} to="/">
        Rasmus Olsson
      </Link>
    );
  }
  return (
    <div>
      <Link className={container} to="/">
        Rasmus Olsson
      </Link>
      <span className={`${container} ${tags}`}> {tag}</span>
    </div>
  );
};
